import React from 'react'
import Layout from 'layouts/en'
import Template from 'templates/terms-and-conditions'

export default () => {
  return (
    <Layout p={5}>
      <Template lang="de" content={content} />
    </Layout>
  )
}

const content = (
  <div className="template">
    <h1 className="headline">Geschäftsbedingungen</h1>
    <div className="paragraph">
      <p className="text">
        Letzte Aktualisierung: July 08, 2017
        <br />
        Diese Allgemeinen Geschäftsbedingungen ( "AGB", "Geschäftsbedingungen")
        regeln Ihre Beziehung zu der Stairlin mobilen Anwendung (der "Service"),
        die von Stairlin AG ( "uns", "wir" oder "unsere") betrieben wird.
        <br />
        Bitte lesen Sie diese Geschäftsbedingungen sorgfältig durch, bevor Sie
        unsere mobile Anwendung Stairlin (den "Service") verwenden.
        <br />
        Ihr Zugang zu und die Nutzung des Dienstes setzen Ihre Annahme und die
        Einhaltung dieser Bedingungen voraus. Diese Bedingungen gelten für alle
        Besucher, Nutzer und andere Personen, die den Service nutzen oder auf
        ihn zugreifen.
        <br />
        Durch den Zugriff auf oder die Nutzung des Dienstes erklären Sie sich
        einverstanden, an diese Bedingungen gebunden zu sein. Wenn Sie mit einem
        Teil der Bedingungen nicht einverstanden sind, dürfen Sie nicht auf den
        Service zugreifen.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Einkäufe</p>
      <p className="text">
        Wenn Sie ein Produkt oder eine Dienstleistung erwerben möchten, die über
        den Service ( "Purchase") zur Verfügung gestellt wird, werden Sie
        möglicherweise aufgefordert, bestimmte für Ihren Einkauf relevante
        Informationen, einschließlich der Kreditkartennummer, des
        Gültigkeitsdatums Ihrer Kreditkarte, Ihre Rechnungsadresse und Ihre
        Versandinformationen.
        <br />
        Sie erklären und garantieren, dass (i) Sie das Recht haben, eine
        beliebige Kreditkarte (n) oder andere Zahlungsmethode (n) im
        Zusammenhang mit einem Kauf zu verwenden; Und dass (ii) die
        Informationen, die Sie uns zur Verfügung stellen, wahr, richtig und
        vollständig sind.
        <br />
        Mit der Übermittlung dieser Informationen gewähren Sie uns das Recht,
        die Informationen an Dritte weiterzugeben, um die Kaufabwicklung zu
        erleichtern.
        <br />
        Wir behalten uns das Recht vor, Ihre Bestellung abzulehnen oder sie zu
        stornieren, und zwar aus bestimmten Gründen, einschließlich, aber nicht
        beschränkt auf: Produkt- oder Serviceverfügbarkeit, Fehler in der
        Beschreibung oder beim Preis des Produkts oder der Dienstleistung,
        Fehler bei Ihrer Bestellung oder andere Gründe.
        <br />
        Wir behalten uns das Recht vor, Ihre Bestellung abzulehnen oder zu
        stornieren, wenn Betrug oder eine unerlaubte oder illegale Transaktion
        vermutet wird.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Verfügbarkeit, Fehler und Ungenauigkeiten</p>
      <p className="text">
        Wir aktualisieren ständig unser Angebot an Produkten und
        Dienstleistungen im Service. Die Produkte oder Dienstleistungen, die auf
        unserem Service verfügbar sind, können mit einem falschen Preis
        ausgezeichnet, unzutreffend beschrieben oder nicht verfügbar sein, und
        es kann Verzögerungen bei der Aktualisierung von Informationen über den
        Service und in unserer Werbung auf anderen Websites geben.
        <br />
        Wir können und werden nicht die Richtigkeit und Vollständigkeit der
        Informationen garantieren, einschließlich Preise, Produktbilder,
        Spezifikationen, Verfügbarkeit und Dienstleistungen. Wir behalten uns
        das Recht vor, Änderungen oder Aktualisierungen vorzunehmen und Fehler,
        Ungenauigkeiten oder fehlende Informationen jederzeit ohne vorherige
        Ankündigung zu korrigieren.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Wettbewerbe, Gewinnspiele und Promotions</p>
      <p className="text">
        Eventuelle Wettbewerbe, Gewinnspiele oder andere Promotions
        (zusammengefasst "Promotions"), die über den Service zur Verfügung
        gestellt werden, können von Regeln abhängen, die von diesen Bedingungen
        abweichen. Wenn Sie an Promotions teilnehmen, beachten Sie bitte die
        dafür geltenden Regeln sowie unsere Datenschutzrichtlinie. Wenn die
        Regeln für eine Promotion mit diesen Bedingungen in Konflikt stehen,
        gelten die Regeln für die Promotions.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Abonnements</p>
      <p className="text">
        Einige Teile des Services werden auf Abonnement-Basis abgerechnet
        ("Subscription (s)"," Abo(s)"). Sie werden im Voraus auf einer
        wiederkehrenden und periodischen Basis ("Abrechnungsperiode")
        abgerechnet. Die Abrechnungsperiode beträgt entweder einen Monat oder
        ein Jahr, abhängig davon, welches Abo Sie beim Kauf ausgewählt haben.
        <br />
        Am Ende jedes Abrechnungszyklus verlängert sich das Abonnement
        automatisch zu den exakt gleichen Bedingungen, es sei denn, es wird von
        Ihnen oder von Stairlin AG gekündigt. Sie können Ihre Abo-Erneuerung
        entweder über Ihre Online-Account-Management-Seite oder durch
        Kontaktaufnahme mit dem Stairlin AG Kundensupport-Team kündigen.
        <br />
        Eine gültige Zahlungsmethode, einschließlich Kreditkarte oder PayPal,
        ist erforderlich, um die Zahlung für Ihr Abonnement zu bearbeiten. Sie
        müssen Stairlin AG mit genauen und vollständigen Zahlungsinformationen
        einschließlich vollständiger Name, Adresse, Staat, Postleitzahl,
        Telefonnummer und einer gültigen Zahlungsmethoden-Information versehen.
        Mit der Übermittlung dieser Zahlungsinformationen berechtigen Sie
        Stairlin AG automatisch, alle für Ihr Konto entstandenen
        Abonnementgebühren über dieses Zahlungsmittel zu erheben.
        <br />
        Sollte der automatische Rechnungseinzug aus irgendeinem Grund
        fehlschlagen, stellt Stairlin AG eine elektronische Rechnung aus, der
        Sie entnehmen können, dass Sie innerhalb einer bestimmten Frist die
        Bezahlung des vollen Betrags, der dem in der Rechnung angegebenen
        Abrechnungszeitraum entspricht, manuell vornehmen müssen.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Kostenloses Probeabo</p>
      <p className="text">
        Stairlin AG kann nach eigenem Ermessen ein Abonnement mit einer
        kostenlosen Testversion für einen begrenztFalls Sie Ihre
        Abrechnungsinformationen eingeben, wenn Sie sich für die kostenlose
        Testversion anmelden, werden diese nicht von Stairlin AG belastet, bis
        die kostenlose Testversion abgelaufen ist. Wenn Sie das Abonnement nicht
        gekündigt haben, werden Ihnen am letzten Tag des kostenlosen Zeitraums
        automatisch die entsprechenden Gebühren für das Abo, das Sie ausgewählt
        haben, berechnet.
        <br />
        Jederzeit und ohne Vorankündigung behält sich Stairlin AG das Recht vor,
        (i) die Bedingungen des Prob-Abo--Angebots zu ändern oder (ii) das
        kostenlose Testangebot einzustellen.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Änderung der Gebühren</p>
      <p className="text">
        Stairlin AG kann nach eigenem Ermessen und jederzeit die Gebühren für
        die Abos ändern. Jede Änderung der Abonnementgebühren wird am Ende des
        laufenden Rechnungszyklus wirksam.
        <br />
        Stairlin AG wird Ihnen eine angemessene Vorankündigung für jede Änderung
        der Abonnementgebühren geben, um Ihnen die Möglichkeit zu geben, Ihr
        Abonnement zu kündigen, bevor diese Änderung wirksam wird.
        <br />
        Ihre fortgesetzte Nutzung des Service nach Inkrafttreten der Änderung
        der Abonnementgebühren stellt Ihr Einverständnis dar, die geänderten
        Abonnementgebühr zu zahlen.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Erstattungen</p>
      <p className="text">
        Soweit nichts anderes gesetzlich vorgeschrieben ist, werden die
        bezahlten Gebühren nicht zurückerstattet.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Inhalte (Öffentlich verteilt)</p>
      <p className="text">
        Unser Service ermöglicht es Ihnen, bestimmte Informationen, Texte,
        Grafiken, Videos oder anderes Material ("Inhalte") zu veröffentlichen,
        zu verlinken, zu speichern, weiterzugeben und anderweitig zur Verfügung
        zu stellen. Sie sind für den Inhalt verantwortlich, den Sie an den
        Dienst senden, einschließlich seiner Rechtmäßigkeit, Zuverlässigkeit und
        Angemessenheit.
        <br />
        Indem Sie Inhalte an den Service senden, gewähren Sie uns das Recht und
        die Lizenz, diese Inhalte auf dem und durch den Service zu nutzen, zu
        modifizieren, öffentlich darzustellen, öffentlich anzuzeigen, zu
        reproduzieren und zu verbreiten. Sie behalten sämtliche Rechte an den
        von Ihnen übermittelten Inhalten, die Sie auf dem oder durch den Service
        einreichen, und Sie sind für den Schutz dieser Rechte verantwortlich.
        Sie erklären sich damit einverstanden, dass diese Lizenz das Recht
        beinhaltet, dass wir Ihre Inhalte anderen Nutzern des Dienstes zur
        Verfügung stellen, die auch die Inhalte im Rahmen dieser Bedingungen
        nutzen können.
        <br />
        Sie erklären und garantieren, dass: (i) der Inhalt Ihrer ist (Sie
        besitzen ihn), oder Sie haben das Recht, ihn zu nutzen und uns die
        Rechte und Lizenzen gemäß diesen Nutzungsbedingungen zu gewähren, und
        (ii) die Veröffentlichung Ihrer Inhalte auf oder durch den Service nicht
        die Rechte, die Privatsphäre, Veröffentlichungsrechte, Urheberrechte,
        Vertragsrechte oder andere Rechte einer Person verletzen.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Inhalte (Privat)</p>
      <p className="text">
        Inhalte, die auf unserer Plattform gespeichert sind und privat bleiben
        sollten werden sollten (vor allem Kundeninformationen, Notizen, Bilder
        und Videos) sind sicher gespeichert und nicht öffentlich zugänglich. Sie
        alleine haben die Möglichkeit auf diese Informationen zuzugreifen, diese
        zu ändern oder zu löschen.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Konten</p>
      <p className="text">
        Wenn Sie ein Konto bei uns erstellen, müssen Sie uns Informationen, die
        jederzeit korrekt, vollständig und aktuell sind, angeben. Die
        Nichteinhaltung dieser Bedingungen stellt einen Verstoß gegen die
        Nutzungsbedingungen dar, der zur sofortigen Kündigung Ihres Kontos auf
        unserem Service führen kann.
        <br />
        Sie sind verantwortlich für die Sicherung des Telefonnummers und des
        Kennworts, das Sie für den Zugriff auf den Dienst verwenden, und für
        alle Aktivitäten oder Aktionen unter Ihrem Telefonnummer und Kennwort,
        unabhängig davon, ob Ihr Kennwort für unserem Dienst oder einen
        Drittanbieter-Dienst erstellt wurde.
        <br />
        Sie stimmen zu, Ihr Passwort nicht an Dritte weiterzugeben. Sie müssen
        uns unverzüglich benachrichtigen, wenn Sie Kenntnis von einer Verletzung
        der Sicherheit oder der unbefugten Nutzung Ihres Kontos erhalten.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Geistiges Eigentum</p>
      <p className="text">
        Der Dienst und sein ursprünglicher Inhalt (ohne die von den Benutzern
        bereitgestellten Inhalte), seine Funktionen und Funktionalitäten sind
        und bleiben Eigentum von Stairlin AG und seinen Lizenzgebern. Der
        Service ist durch Copyright, Warenzeichen und andere Gesetze sowohl von
        Switzerland als auch anderer Länder geschützt. Unsere Marken und
        Handelsbezeichnungen dürfen ohne vorherige schriftliche Zustimmung von
        Stairlin AG nicht in Verbindung mit Produkten oder Dienstleistungen
        verwendet werden.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Links zu anderen Webseiten</p>
      <p className="text">
        Unser Service kann Links zu Websites von Drittanbietern oder Diensten
        enthalten, die nicht Eigentum von Stairlin AG sind.
        <br />
        Stairlin AG hat keine Kontrolle darüber und übernimmt keinerlei
        Verantwortung für den Inhalt, die Datenschutzbestimmungen oder Praktiken
        von Webseiten oder Diensten Dritter. Sie erklären sich ferner damit
        einverstanden, dass Stairlin AG weder direkt noch indirekt für
        irgendwelche Schäden oder Verluste verantwortlich oder haftbar ist, die
        durch oder im Zusammenhang mit der Nutzung oder dem Vertrauen auf solche
        Inhalte, Waren oder Dienstleistungen auf oder durch solche Webseiten
        oder Dienste.verursacht wurden.
        <br />
        Wir empfehlen Ihnen dringend, die Nutzungsbedingungen und
        Datenschutzrichtlinien von Drittanbieter-Websites oder Diensten, die Sie
        besuchen, zu lesen.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Kündigung</p>
      <p className="text">
        Wir können Ihr Konto unverzüglich, ohne vorherige Ankündigung oder
        Haftung, aus jedwedem Grund, ohne jegliche Einschränkung, wenn Sie gegen
        die AGBs verstoßen, kündigen oder sperren.
        <br />
        Bei Kündigung wird Ihr Nutzungsrecht sofort eingestellt. Wenn Sie Ihr
        Konto kündigen möchten, können Sie die Nutzung des Dienstes einfach
        beenden.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Haftungsbeschränkung</p>
      <p className="text">
        In keinem Fall haften Stairlin AG sowie ihre Direktoren, Mitarbeiter,
        Partner, Vertreter, Lieferanten oder verbundene Unternehmen für
        indirekte, zufällige, besondere, Folgeschäden oder Straf- /
        Entschädigungszahlungen, einschließlich Gewinne, Firmenwerte oder
        sonstige immaterielle Verluste, die sich aus (i) Ihrem Zugang zu oder
        dem Gebrauch oder der Unfähigkeit des Zugangs oder der Nutzung des
        Dienstes ergeben; (Ii) irgendein Verhalten oder Inhalt eines Dritten im
        Dienst; (Iii) Inhalte, die aus dem Dienst erworben wurden; und (iv)
        unberechtigter Zugriff, Nutzung oder Veränderung Ihrer Übertragungen
        oder Inhalte, sei es auf Garantie, Vertrag, unerlaubter Handlung
        (einschließlich Fahrlässigkeit) oder einer anderen gesetzlichen Norm, ob
        wir oder ob wir nicht über die Möglichkeit eines solchen Schadens
        unterrichtet worden sind oder auch falls eine hierin enthaltene Abhilfe
        ihren wesentlichen Zweck verfehlt hat.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Haftungsausschluss</p>
      <p className="text">
        Ihre Nutzung des Service erfolgt auf eigenes Risiko. Der Service wird
        auf einer "AS IS" und "AS AVAILABLE" Basis zur Verfügung gestellt. Der
        Service erfolgt ohne jegliche Gewährleistung, weder ausdrücklich noch
        stillschweigend, einschließlich, aber nicht beschränkt auf implizite
        Garantien der Marktgängigkeit, der Eignung für einen bestimmten Zweck,
        der Nichtverletzung eines Rechtes oder bestimmter Eigenschaften
        bezüglich der Leistung.
        <br />
        Stairlin AG ihre Tochtergesellschaften, verbundene Unternehmen und ihre
        Lizenzgeber geben keine Garantie, dass a) der Dienst ununterbrochen,
        sicher oder zu einem bestimmten Zeitpunkt oder an einem bestimmten Ort
        funktionieren wird; b) dass Fehler oder Mängel berichtigt werden; C) der
        Service frei von Viren oder anderen schädlichen Bestandteilen ist; oder
        d) die Ergebnisse der Nutzung des Dienstes Ihren Anforderungen
        entsprechen werden.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Geltendes Recht</p>
      <p className="text">
        Diese Bedingungen richten sich nach den Gesetzen von Switzerland ohne
        Berücksichtigung von Kollisionsnormen.
        <br />
        Unsere Nichteinforderung eines Rechts oder einer Bestimmung dieser
        Bedingungen wird nicht als Verzicht auf diese Rechte angesehen. Sollte
        eine Bestimmung dieser Bedingungen von einem Gericht als ungültig oder
        undurchführbar angesehen werden, so bleiben die übrigen Bestimmungen
        dieser Bedingungen wirksam. Diese Bedingungen bilden die gesamte
        Vereinbarung zwischen uns über unseren Service und ersetzen alle
        früheren Vereinbarungen, die eventuell zwischen uns bezüglich des
        Service bestehen könnten.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Änderungen</p>
      <p className="text">
        Wir behalten uns das Recht vor, diese Bedingungen nach eigenem Ermessen
        jederzeit zu ändern oder zu ersetzen. Wenn eine Überarbeitung / Änderung
        wesentlich ist, werden wir angemessene Anstrengungen unternehmen, um die
        Änderung mit einer Frist von mindestens 30 Tagen vor dem Wirksamwerden
        neuer Bestimmungen anzukündigen. Was eine wesentliche Änderung
        darstellt, wird nach unserem eigenen Ermessen bestimmt.
        <br />
        Wenn Sie unseren Service weiterhin nutzen oder nutzen, nachdem diese
        Änderungen wirksam geworden sind, erklären Sie sich damit einverstanden,
        an die überarbeiteten Bestimmungen gebunden zu sein. Wenn Sie mit den
        neuen Bestimmungen nicht ganz oder teilweise nicht einverstanden sind,
        beenden Sie bitte die Nutzung der Webseite und des Service.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Kontakt</p>
      <p className="text">
        Falls Sie Fragen zu diesen Bedingungen haben, kontaktieren Sie uns
        bitte.
      </p>
    </div>
  </div>
);
